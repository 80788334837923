import React from "react";
import { useTranslation } from "react-i18next";
import imagen1 from "./penalolen-05-850x600-c-default.jpg";
import imagen2 from "./vina_del_mar-e1602078115600.jpg";
import { Link } from "react-router-dom";

const Calendario = () => {
  const [t, i18n] = useTranslation("global");

  return (
      <div className="container">
        <h1 className="text-center my-4">{t("ambos.calendario.titulo")}</h1>
        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4" />
        <div className="row mt-5 justify-content-center ">
          {/* Calendario de santiago*/}
          <div className="col-12 col-md-6 col-lg-4 mb-4">
            <div className="card text-center h-100 mt-5">
              <Link
                to="https://uaidocs.blob.core.windows.net/publico/pruebas_santiago.html"
                className="link-offset-2 link-underline link-underline-opacity-0"
              >
                <img
                  src={imagen1}
                  className="card-img-top img-fluid object-fit-cover"
                  alt="..."
                  style={{ width: "100%", height: "200px", objectFit: "cover" }}
                />
                <div className="card-body">
                  <h5 className="card-title text-dark">{t("ambos.calendario.calendario_santiago")}</h5>
                </div>
              </Link>
            </div>
          </div>
          {/* calendario de viña */}
          <div className="col-12 col-md-6 col-lg-4 mb-4">
            <div className="card text-center h-100 mt-5">
              <Link
                to="https://uaidocs.blob.core.windows.net/publico/pruebas_vina.html"
                className="link-offset-2 link-underline link-underline-opacity-0"
              >
                <img
                  src={imagen2}
                  className="card-img-top img-fluid object-fit-cover"
                  alt="..."
                  style={{ width: "100%", height: "200px", objectFit: "cover" }}
                />
                <div className="card-body">
                  <h5 className="card-title text-dark">{t("ambos.calendario.calendario_viña")}</h5>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default Calendario;
