import React from "react";

import work from "../../resources/work.jpg"

import guiainformepractica from "../Practicas/pdf/GuiaInformePractica.pdf"



import { useTranslation } from "react-i18next";
const ProcedimientosPracticas = () =>{
    const [t, i18n]= useTranslation("global");
    return(
        <>
        {/*}Renderizado hasta pantallas xl{*/}
        <div className="d-none d-xl-block">
            <div className=""> 
                <div className="col-md-6 offset-md-3">       
                    <div className="card position-relative ms-4 me-4 ">
                        <img src={work}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("practicas.procedimientos.titulo")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
                </div>
            </div>
    
            <div className="p-3"/>
            <div className="col-md-6 offset-md-3">
                
                <p className="text-start fs-6">{t("practicas.procedimientos.descripcion")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6"><strong>{t("practicas.procedimientos.explicacion_titulo")}</strong>{t("practicas.procedimientos.explicacion")}</p>
                <div className="p-2"/>
            </div>
            <div className="bg-grey">
                <div className="p-4"/>
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">     
                        <h1 className="text-center fs-3">{t("practicas.procedimientos.aspectos")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    
                        <div className="p-3"/>
                        <ul>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_empresa")}</li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_certificacion")}<a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.mail_practicaslaborales")}</a></li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_certificado_texto1")} <a className="text-decoration-none color-uai" href="https://solicitudes.uai.cl/">{t("practicas.procedimientos.pagina_solicitudes")}</a>{t("practicas.procedimientos.aspecto_certificado_texto2")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_poliza-seguro")}</li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_objetada_texto1")}<strong>{t("practicas.procedimientos.aspecto_objetada_estado")}</strong>{t("practicas.procedimientos.aspecto_objetada_texto2")}<a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.mail_practicaslaborales")}</a>{t("practicas.procedimientos.aspecto_objetada_texto3")}</li>
                            <div className="p-1"/>
                            
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_rechazado_texto1")} <strong>{t("practicas.procedimientos.aspecto_rechazado_estado")}</strong> {t("practicas.procedimientos.aspecto_rechazado_texto2")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_informe-practica_texto1")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=252">{t("practicas.procedimientos.aspecto_informe-practica_enlace")}</a> {t("practicas.procedimientos.aspecto_informe-practica_texto2")} <strong>{t("practicas.procedimientos.aspecto_informe-practica_consideracion")}</strong>.</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_desempeño")}</li>
                            <div className="p-1"/>
                            <table class = "table table-hover table-bordered m-0">
                                <thead class= "table-dark">
                                    <tr>
                                    <th scope="col">{t("practicas.procedimientos.aspecto_tabla-informe_titulo")}</th>
                                    <th scope="col">{t("practicas.procedimientos.aspecto_tabla-nota_titulo")}</th>

                                    </tr>
                                </thead>
                                <tbody>
                
                                                <tr>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-informe_opcion1")}</td>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-nota_opcion1")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-informe_opcion2")}</td>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-nota_opcion2")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-informe_opcion3")}</td>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-nota_opcion3")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-informe_opcion4")}</td>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-nota_opcion4")}</td>
                                                </tr>
                                </tbody>
                            </table>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_instruccion-desempeño")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.dudas")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.mail_practicaslaborales")}</a></li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.descarga")} <a className="text-decoration-none color-uai" href={guiainformepractica}>{t("practicas.procedimientos.guia_enlace")}</a>.</li>
                        </ul>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    </div>
                    <div className="p-4"/> 
                </div>
            </div>
        
        </div>
        
        {/*}Renderizado desde pantallas lg{*/}
        <div className="d-xl-none">
            <div className="d-none d-md-block">
            <div className="">        
                        <div className="card position-relative">
                            <img src={work}className="card-img"/>
                            <div className="card-img-overlay position-absolute custom-co-image">
                                <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("practicas.procedimientos.titulo")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                            </div>
                        </div>
                </div>
                <div className="p-3"/>
            <div className="col-md-6 offset-md-3">
                <p className="text-start fs-6">{t("practicas.procedimientos.descripcion")}</p>
                <div className="p-2"/>
                <p className="text-start fs-6"><strong>{t("practicas.procedimientos.explicacion_titulo")}</strong>{t("practicas.procedimientos.explicacion")}</p>
                
                <div className="p-2"/>
            </div>
                <div className="bg-grey">
                <div className="p-4"/>
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">     
                        <h1 className="text-center fs-3">{t("practicas.procedimientos.aspectos")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    
                        <div className="p-3"/>
                        <ul>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_empresa")}</li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_certificacion")}<a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.mail_practicaslaborales")}</a></li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_certificado_texto1")} <a className="text-decoration-none color-uai" href="https://solicitudes.uai.cl/">{t("practicas.procedimientos.pagina_solicitudes")}</a>{t("practicas.procedimientos.aspecto_certificado_texto2")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_poliza-seguro")}</li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_objetada_texto1")}<strong>{t("practicas.procedimientos.aspecto_objetada_estado")}</strong>{t("practicas.procedimientos.aspecto_objetada_texto2")}<a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.mail_practicaslaborales")}</a>{t("practicas.procedimientos.aspecto_objetada_texto3")}</li>
                            <div className="p-1"/>
    
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_rechazado_texto1")} <strong>{t("practicas.procedimientos.aspecto_rechazado_estado")}</strong> {t("practicas.procedimientos.aspecto_rechazado_texto2")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_informe-practica_texto1")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=252">{t("practicas.procedimientos.aspecto_informe-practica_enlace")}</a> {t("practicas.procedimientos.aspecto_informe-practica_texto2")} <strong>{t("practicas.procedimientos.aspecto_informe-practica_consideracion")}</strong>.</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_desempeño")}</li>
                            <table class = "table table-hover table-bordered m-0">
                                <thead class= "table-dark">
                                    <tr>
                                    <th scope="col">{t("practicas.procedimientos.aspecto_tabla-informe_titulo")}</th>
                                    <th scope="col">{t("practicas.procedimientos.aspecto_tabla-nota_titulo")}</th>

                                    </tr>
                                </thead>
                                <tbody>
                
                                                <tr>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-informe_opcion1")}</td>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-nota_opcion1")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-informe_opcion2")}</td>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-nota_opcion2")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-informe_opcion3")}</td>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-nota_opcion3")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-informe_opcion4")}</td>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-nota_opcion4")}</td>
                                                </tr>
                                </tbody>
                            </table>
                            <div className="p-1"/>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_instruccion-desempeño")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.dudas")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.mail_practicaslaborales")}</a></li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.descarga")} <a className="text-decoration-none color-uai" href={guiainformepractica}>{t("practicas.procedimientos.guia_enlace")}</a>.</li>
                        </ul>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    </div>
                    <div className="p-4"/> 
                </div>
            </div>
                
                
            </div>
    
            <div className="d-md-none">
                <div className="card border-0">
                    <div className="row g-0 ">
                        <div className="col-md-6">
                            <img src={work} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-5">
                            <div className="card-body position-relative">
                                {/*}Textos{*/}
                                <h1 className="card-title text-center">{t("practicas.procedimientos.titulo")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                <div className="p-3"/>
                                <p className="text-start fs-6">{t("practicas.procedimientos.descripcion")}</p>
                                <div className="p-2"/>
                                <p className="text-start fs-6"><strong>{t("practicas.procedimientos.explicacion_titulo")}</strong>{t("practicas.procedimientos.explicacion")}</p>
                                
                                <div className="p-2"/>  
                            </div>
                            <div className="bg-grey">
                <div className="p-4"/>
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">     
                        <h1 className="text-center fs-3">{t("practicas.procedimientos.aspectos")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    
                        <div className="p-3"/>
                        <ul>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_empresa")}</li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_certificacion")}<a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.mail_practicaslaborales")}</a></li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_certificado_texto1")} <a className="text-decoration-none color-uai" href="https://solicitudes.uai.cl/">{t("practicas.procedimientos.pagina_solicitudes")}</a>{t("practicas.procedimientos.aspecto_certificado_texto2")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_poliza-seguro")}</li> 
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_objetada_texto1")}<strong>{t("practicas.procedimientos.aspecto_objetada_estado")}</strong>{t("practicas.procedimientos.aspecto_objetada_texto2")}<a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.mail_practicaslaborales")}</a>{t("practicas.procedimientos.aspecto_objetada_texto3")}</li>
                            <div className="p-1"/>
                            
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_rechazado_texto1")} <strong>{t("practicas.procedimientos.aspecto_rechazado_estado")}</strong> {t("practicas.procedimientos.aspecto_rechazado_texto2")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_informe-practica_texto1")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=252">{t("practicas.procedimientos.aspecto_informe-practica_enlace")}</a> {t("practicas.procedimientos.aspecto_informe-practica_texto2")} <strong>{t("practicas.procedimientos.aspecto_informe-practica_consideracion")}</strong>.</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_desempeño")}</li>
                            <div className="p-1"/>
                            <table class = "table table-hover table-bordered m-0">
                                <thead class= "table-dark">
                                    <tr>
                                    <th scope="col">{t("practicas.procedimientos.aspecto_tabla-informe_titulo")}</th>
                                    <th scope="col">{t("practicas.procedimientos.aspecto_tabla-nota_titulo")}</th>

                                    </tr>
                                </thead>
                                <tbody>
                
                                                <tr>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-informe_opcion1")}</td>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-nota_opcion1")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-informe_opcion2")}</td>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-nota_opcion2")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-informe_opcion3")}</td>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-nota_opcion3")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-informe_opcion4")}</td>
                                                    <td scope="row">{t("practicas.procedimientos.aspecto_tabla-nota_opcion4")}</td>
                                                </tr>
                                </tbody>
                            </table>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.aspecto_instruccion-desempeño")}</li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.dudas")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.procedimientos.mail_practicaslaborales")}</a></li>
                            <div className="p-1"/>
                            <li className="fs-6">{t("practicas.procedimientos.descarga")} <a className="text-decoration-none color-uai" href={guiainformepractica}>{t("practicas.procedimientos.guia_enlace")}</a>.</li>
                        </ul>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                    </div>
                    <div className="p-4"/> 
                </div>
                </div>
                            
                            
            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </>
        )

}

export default ProcedimientosPracticas