import React, {useState} from "react";
import {Button, Collapse} from "react-bootstrap";
import pasantia from "../../resources/pasantia.jpg"
import plazos from "../../resources/plazos.png"
import reglamentopasantia from "../Mecanismo_de_Titulacion/pdfs/Reglamentopasantia2023.pdf"

import { useTranslation } from "react-i18next";

const PasantiaTitulacion = () =>{
    const [t, i18n]= useTranslation("global");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);
    const [open9, setOpen9] = useState(false);
    const [open10, setOpen10] = useState(false);
    const [open11, setOpen11] = useState(false);
    const [open12, setOpen12] = useState(false);
    const [open13, setOpen13] = useState(false);
    const [open14, setOpen14] = useState(false);
    const [open15, setOpen15] = useState(false);

    return(
    <>
    
    {/*}Renderizado hasta pantallas xl{*/}
    <div className="d-none d-xl-block">
        <div className="col-md-6 offset-md-3">        
                <div className="card position-relative ms-4 me-4">
                    <img src={pasantia}className="card-img"/>
                    <div className="card-img-overlay position-absolute custom-co-image">
                        <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("titulacion.pasantia.t1")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                    </div>
                </div>
        </div>
        <div className="p-3"/>
        
        <div className="col-md-6 offset-md-3">
                <div className="p-2"/>
                <h1 className="text-center fs-3">{t("titulacion.pasantia.t2")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
                <p className="text-start fs-6">{t("titulacion.pasantia.t3")}<strong>{t("titulacion.pasantia.t47")}</strong>{t("titulacion.pasantia.t48")}<strong>{t("titulacion.pasantia.t49")}</strong>{t("titulacion.pasantia.t50")}<strong>{t("titulacion.pasantia.t51")}</strong>{t("titulacion.pasantia.t52")}</p>
                <div className="p-3"/>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
        </div>
        <div className="bg-grey">
            <div className="col-md-6 offset-md-3">
                <div className="p-3"/>
                <h1 className="text-center fs-3">{t("titulacion.pasantia.titulo_guia_practica")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
                <ul>
                    <li className="fs-6">{t("titulacion.pasantia.guia_practica1_texto1")}<a className="text-decoration-none color-uai" href="https://view.monday.com/3363094659-04594d43d0706af427105d07ca5769d8?r=use1">{t("titulacion.pasantia.guia_practica1_enlace1")}</a></li> 
                    <li className="fs-6">{t("titulacion.pasantia.guia_practica2_texto1")}<a className="text-decoration-none color-uai" href="https://wkf.ms/3LzkIUs">{t("titulacion.pasantia.guia_practica2_enlace1")}</a>{t("titulacion.pasantia.guia_practica2_texto2")}<a className="text-decoration-none color-uai" href="https://view.monday.com/7047028100-5d4ff8b7e8f9ceb66633829bc4fdfd21?r=use1">{t("titulacion.pasantia.guia_practica2_enlace2")}</a></li>.
                    <li className="fs-6">{t("titulacion.pasantia.guia_practica3_texto1")}</li>
                    <li className="fs-6">{t("titulacion.pasantia.guia_practica4_texto1")}<a className="text-decoration-none color-uai" href="http://gestionfic.uai.cl/login">{t("titulacion.pasantia.link_gestionfic")}</a>.</li>
                    <li className="fs-6">{t("titulacion.pasantia.guia_practica5_texto1")}</li>
                    <li className="fs-6">{t("titulacion.pasantia.guia_practica6_texto1")}<a className="text-decoration-none color-uai" href="mailto:concejodocente.fic@uai.cl">{t("titulacion.pasantia.correo_consejodocente")}</a>.</li>
                    
                </ul>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
            </div>
        </div>
                

        <div className="bg-grey">
            <div className="col-md-6 offset-md-3">
                <div className="p-3"/>
                <h1 className="text-center fs-3">{t("titulacion.pasantia.titulo_reglamento")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
                <p className="text-start fs-6">{t("titulacion.pasantia.reglamento_descripcion1")}<a className="text-decoration-none color-uai" href={reglamentopasantia}>{t("titulacion.pasantia.reglamento_descripcion_enlace")}</a>{t("titulacion.pasantia.reglamento_descripcion2")}</p>
                <div className="p-3"/>
                <ul>
                    <li className="fs-6">{t("titulacion.pasantia.reglamento_objetivos")}</li> 
                    <li className="fs-6">{t("titulacion.pasantia.reglamento_requisitos")}</li>
                    <li className="fs-6">{t("titulacion.pasantia.reglamento_empresas")}</li>
                    <li className="fs-6">{t("titulacion.pasantia.reglamento_modalidades")}</li>
                    <li className="fs-6">{t("titulacion.pasantia.reglamento_deberes")}</li>
                    <li className="fs-6">{t("titulacion.pasantia.reglamento_otros")}</li>
                </ul>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
            </div>
        </div>
            

            <div className="col-md-6 offset-md-3">
                <div className="position-relative">
                    <div className="p-3"/>
                        <h1 className="text-center fs-3">{t("titulacion.pasantia.titulo_preguntas")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        <div className="p-3"/>
                        <Button
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.requisitos.pregunta")}</p>
                        </Button>
                        <Collapse in={open}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.requisitos.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen2(!open2)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open2}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.modalidad.pregunta")}</p>
                        </Button>
                        <Collapse in={open2}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.modalidad.respuesta")}</p>
                                <ul>
                                    <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.modalidad.respuesta_fulltime")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.modalidad.respuesta_partime")}</li>
                            </ul>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                               <div className="p-2"/>
                        <Button
                            onClick={() => setOpen3(!open3)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open3}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.carga_horaria.pregunta")}</p>
                        </Button>
                        <Collapse in={open3}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <ul>
                                    <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.modalidad.respuesta_fulltime")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.carga_horaria.respuesta_partime")}</li>
                            </ul>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                    
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen4(!open4)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open4}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.sin_convenio.pregunta")}</p>
                        </Button>
                        <Collapse in={open4}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.sin_convenio.respuesta_texto1")}<a className="text-decoration-none color-uai" href="https://wkf.ms/3LzkIUs">{t("titulacion.pasantia.sin_convenio_formulario")}</a>{t("titulacion.pasantia.sin_convenio.respuesta_texto2")}<a className="text-decoration-none color-uai" href=" https://view.monday.com/7047028100-5d4ff8b7e8f9ceb66633829bc4fdfd21?r=use1">{t("titulacion.pasantia.sin_convenio_estado_link")}</a>{t("titulacion.pasantia.sin_convenio.respuesta_texto3")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen5(!open5)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open5}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.plazo.pregunta")}</p>
                        </Button>
                        <Collapse in={open5}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.plazo.respuesta_texto1")}<a className="text-decoration-none color-uai" href="http://gestionfic.uai.cl/login">{t("titulacion.pasantia.link_gestionfic")}</a> {t("titulacion.pasantia.plazo.respuesta_texto2")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen6(!open6)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open6}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.oferta_pasantias.pregunta")}</p>
                        </Button>
                        <Collapse in={open6}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.oferta_pasantia.respuesta_texto")} <a className="text-decoration-none color-uai" href="https://view.monday.com/4646454937-90321b766cd9d01e65a2980828a5a585?r=use1">{t("titulacion.pasantia.oferta_pasantia.respuesta_enlace")}</a>.</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen7(!open7)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open7}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.homologacion.pregunta")}</p>
                        </Button>
                        <Collapse in={open7}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.homologacion.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen8(!open8)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open8}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.remuneracion.pregunta")}</p>
                        </Button>
                        <Collapse in={open8}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.remuneracion.respuesta")} </p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen9(!open9)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open9}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.convenio.pregunta")}</p>
                        </Button>
                        <Collapse in={open9}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.convenio.respuesta_texto1")} <a className="text-decoration-none color-uai" href="https://view.monday.com/3363094659-04594d43d0706af427105d07ca5769d8?r=use1">{t("titulacion.pasantia.convenio.respuesta_enlace")}</a>{t("titulacion.pasantia.convenio.respuesta_texto2")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen10(!open10)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open10}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.asignacion_pasantia.pregunta")}</p>
                        </Button>
                        <Collapse in={open10}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.asignacion_pasantia.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen11(!open11)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open11}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.inscripcion_profesor.pregunta")}</p>
                        </Button>
                        <Collapse in={open11}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.inscripcion_profesor.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen12(!open12)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open12}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.estado_convenio.pregunta")}</p>
                        </Button>
                        <Collapse in={open12}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.estado_convenio.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen13(!open13)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open13}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.certificado_pasantia.pregunta")}</p>
                        </Button>
                        <Collapse in={open13}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.certificado_pasantia.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen14(!open14)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open14}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.suspendido_pasantia.pregunta")}</p>
                        </Button>
                        <Collapse in={open14}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.suspendido_pasantia.respuesta_texto1")} <a className="text-decoration-none color-uai" href="https://wkf.ms/3Go4MBY">{t("titulacion.pasantia.suspendido_pasantia.respuesta_formulario")}</a>{t("titulacion.pasantia.suspendido_pasantia.respuesta_texto2")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen15(!open15)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open15}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.finalizacion_pasantia.pregunta")}</p>
                        </Button>
                        <Collapse in={open15}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.finalizacion_pasantia.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-3"/>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                </div>

            </div>
    </div>
    
    {/*}Renderizado desde pantallas lg{*/}
    <div className="d-xl-none">
        <div className="d-none d-md-block">
        <div className="">        
                    <div className="card position-relative">
                        <img src={pasantia}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("titulacion.pasantia.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>

            <div className="p-3"/>
            <div className="col-md-6 offset-md-3">
                <div className="p-2"/>
                <h1 className="text-center fs-3">{t("titulacion.pasantia.t2")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
                <p className="text-start fs-6">{t("titulacion.pasantia.t3")}<strong>{t("titulacion.pasantia.t47")}</strong>{t("titulacion.pasantia.t48")}<strong>{t("titulacion.pasantia.t49")}</strong>{t("titulacion.pasantia.t50")}<strong>{t("titulacion.pasantia.t51")}</strong>{t("titulacion.pasantia.t52")}</p>
                <div className="p-3"/>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
        </div>
        <div className="bg-grey">
            <div className="col-md-6 offset-md-3">
                <div className="p-3"/>
                <h1 className="text-center fs-3">{t("titulacion.pasantia.titulo_guia_practica")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
                <ul>
                    <li className="fs-6">{t("titulacion.pasantia.guia_practica1_texto1")}<a className="text-decoration-none color-uai" href="https://view.monday.com/3363094659-04594d43d0706af427105d07ca5769d8?r=use1">{t("titulacion.pasantia.guia_practica1_enlace1")}</a></li> 
                    
                    <li className="fs-6">{t("titulacion.pasantia.guia_practica2_texto1")}<a className="text-decoration-none color-uai" href="https://wkf.ms/3LzkIUs">{t("titulacion.pasantia.guia_practica2_enlace1")}</a>{t("titulacion.pasantia.guia_practica2_texto2")}<a className="text-decoration-none color-uai" href="https://view.monday.com/7047028100-5d4ff8b7e8f9ceb66633829bc4fdfd21?r=use1">{t("titulacion.pasantia.guia_practica2_enlace2")}</a>.
                    
                    </li>
                    <li className="fs-6">{t("titulacion.pasantia.guia_practica3_texto1")}</li>
                    <li className="fs-6">{t("titulacion.pasantia.guia_practica4_texto1")}<a className="text-decoration-none color-uai" href="http://gestionfic.uai.cl/login">{t("titulacion.pasantia.link_gestionfic")}</a>.</li>
                    <li className="fs-6">{t("titulacion.pasantia.guia_practica5_texto1")}</li>
                    <li className="fs-6">{t("titulacion.pasantia.guia_practica6_texto1")}<a className="text-decoration-none color-uai" href="mailto:concejodocente.fic@uai.cl">{t("titulacion.pasantia.correo_consejodocente")}</a>.</li>
                    
                    
                </ul>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
            </div>
        </div>
                

        <div className="bg-grey">
            <div className="col-md-6 offset-md-3">
                <div className="p-3"/>
                <h1 className="text-center fs-3">{t("titulacion.pasantia.titulo_reglamento")}</h1>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
                <p className="text-start fs-6">{t("titulacion.pasantia.reglamento_descripcion1")}<a className="text-decoration-none color-uai" href={reglamentopasantia}>{t("titulacion.pasantia.reglamento_descripcion_enlace")}</a>{t("titulacion.pasantia.reglamento_descripcion2")}</p>
                <div className="p-3"/>
                <ul>
                    <li className="fs-6">{t("titulacion.pasantia.reglamento_objetivos")}</li> 
                    <li className="fs-6">{t("titulacion.pasantia.reglamento_requisitos")}</li>
                    <li className="fs-6">{t("titulacion.pasantia.reglamento_empresas")}</li>
                    <li className="fs-6">{t("titulacion.pasantia.reglamento_modalidades")}</li>
                    <li className="fs-6">{t("titulacion.pasantia.reglamento_deberes")}</li>
                    <li className="fs-6">{t("titulacion.pasantia.reglamento_otros")}</li>
                </ul>
                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                <div className="p-3"/>
            </div>
        </div>
            

            <div className="col-md-6 offset-md-3">
                <div className="position-relative">
                    <div className="p-3"/>
                        <h1 className="text-center fs-3">{t("titulacion.pasantia.titulo_preguntas")}</h1>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                        <div className="p-3"/>
                        <Button
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.requisitos.pregunta")}</p>
                        </Button>
                        <Collapse in={open}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.requisitos.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen2(!open2)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open2}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.modalidad.pregunta")}</p>
                        </Button>
                        <Collapse in={open2}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.modalidad.respuesta")}</p>
                                <ul>
                                    <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.modalidad.respuesta_fulltime")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.modalidad.respuesta_partime")}</li>
                            </ul>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                               <div className="p-2"/>
                        <Button
                            onClick={() => setOpen3(!open3)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open3}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.carga_horaria.pregunta")}</p>
                        </Button>
                        <Collapse in={open3}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <ul>
                                    <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.carga_horaria.respuesta_fulltime")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.carga_horaria.respuesta_partime")}</li>
                            </ul>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                    
                    
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen4(!open4)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open4}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.sin_convenio.pregunta")}</p>
                        </Button>
                        <Collapse in={open4}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.sin_convenio.respuesta_texto1")}<a className="text-decoration-none color-uai" href="https://wkf.ms/3LzkIUs">{t("titulacion.pasantia.sin_convenio_formulario")}</a>{t("titulacion.pasantia.sin_convenio.respuesta_texto2")}<a className="text-decoration-none color-uai" href=" https://view.monday.com/7047028100-5d4ff8b7e8f9ceb66633829bc4fdfd21?r=use1">{t("titulacion.pasantia.sin_convenio_estado_link")}</a>{t("titulacion.pasantia.sin_convenio.respuesta_texto3")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen5(!open5)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open5}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.plazo.pregunta")}</p>
                        </Button>
                        <Collapse in={open5}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.plazo.respuesta_texto1")}<a className="text-decoration-none color-uai" href="http://gestionfic.uai.cl/login">{t("titulacion.pasantia.link_gestionfic")}</a>{t("titulacion.pasantia.plazo.respuesta_texto2")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen6(!open6)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open6}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.oferta_pasantias.pregunta")}</p>
                        </Button>
                        <Collapse in={open6}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.oferta_pasantia.respuesta_texto")} <a className="text-decoration-none color-uai" href="https://view.monday.com/4646454937-90321b766cd9d01e65a2980828a5a585?r=use1">{t("titulacion.pasantia.oferta_pasantia.respuesta_enlace")}</a>.</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen7(!open7)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open7}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.homologacion.pregunta")}</p>
                        </Button>
                        <Collapse in={open7}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.homologacion.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen8(!open8)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open8}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.remuneracion.pregunta")}</p>
                        </Button>
                        <Collapse in={open8}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.remuneracion.respuesta")} </p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen9(!open9)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open9}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.convenio.pregunta")}</p>
                        </Button>
                        <Collapse in={open9}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.convenio.respuesta_texto1")} <a className="text-decoration-none color-uai" href="https://view.monday.com/3363094659-04594d43d0706af427105d07ca5769d8?r=use1">{t("titulacion.pasantia.convenio.respuesta_enlace")}</a>{t("titulacion.pasantia.convenio.respuesta_texto2")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen10(!open10)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open10}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.asignacion_pasantia.pregunta")}</p>
                        </Button>
                        <Collapse in={open10}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.asignacion_pasantia.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen11(!open11)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open11}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.inscripcion_profesor.pregunta")}</p>
                        </Button>
                        <Collapse in={open11}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.inscripcion_profesor.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen12(!open12)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open12}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.estado_convenio.pregunta")}</p>
                        </Button>
                        <Collapse in={open12}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.estado_convenio.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen13(!open13)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open13}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.certificado_pasantia.pregunta")}</p>
                        </Button>
                        <Collapse in={open13}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.certificado_pasantia.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen14(!open14)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open14}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.suspendido_pasantia.pregunta")}</p>
                        </Button>
                        <Collapse in={open14}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.suspendido_pasantia.respuesta_texto1")} <a className="text-decoration-none color-uai" href="https://wkf.ms/3Go4MBY">{t("titulacion.pasantia.suspendido_pasantia.respuesta_formulario")}</a>{t("titulacion.pasantia.suspendido_pasantia.respuesta_texto2")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen15(!open15)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open15}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.finalizacion_pasantia.pregunta")}</p>
                        </Button>
                        <Collapse in={open15}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.finalizacion_pasantia.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-3"/>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                </div>

            </div>
        </div>

        <div className="d-md-none">
            <div className="card border-0">
                <div className="row g-0 ">
                    <div className="col-md-6">
                        <img src={pasantia} className="img-fluid rounded-start" alt="..."/>
                    </div>
                    <div className="col-md-5">
                        <div className="card-body position-relative">
                            {/*}Textos{*/}
                            <h1 className="card-title text-center">{t("titulacion.pasantia.t1")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <div className="p-2"/>
                            <h1 className="text-center fs-3">{t("titulacion.pasantia.t2")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <p className="text-start fs-6">{t("titulacion.pasantia.t3")}<strong>{t("titulacion.pasantia.t47")}</strong>{t("titulacion.pasantia.t48")}<strong>{t("titulacion.pasantia.t49")}</strong>{t("titulacion.pasantia.t50")}<strong>{t("titulacion.pasantia.t51")}</strong>{t("titulacion.pasantia.t52")}</p>
                            <div className="p-3"/>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                    
                            <div className="bg-grey">
                                <div className="position-relative">     
                                    <div className="p-3"/>
                                    <h1 className="text-center fs-3">{t("titulacion.pasantia.titulo_guia_practica")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                    <div className="p-3"/>
                                    <ul>
                                        <li className="fs-6">{t("titulacion.pasantia.guia_practica1_texto1")}<a className="text-decoration-none color-uai" href="https://view.monday.com/3363094659-04594d43d0706af427105d07ca5769d8?r=use1">{t("titulacion.pasantia.guia_practica1_enlace1")}</a></li> 
                                        <li className="fs-6">{t("titulacion.pasantia.guia_practica2_texto1")}<a className="text-decoration-none color-uai" href="https://wkf.ms/3LzkIUs">{t("titulacion.pasantia.guia_practica2_enlace1")}</a>{t("titulacion.pasantia.guia_practica2_texto2")}<a className="text-decoration-none color-uai" href="https://view.monday.com/7047028100-5d4ff8b7e8f9ceb66633829bc4fdfd21?r=use1">{t("titulacion.pasantia.guia_practica2_enlace2")}</a></li>.
                                        <li className="fs-6">{t("titulacion.pasantia.guia_practica3_texto1")}</li>
                                        <li className="fs-6">{t("titulacion.pasantia.guia_practica4_texto1")}<a className="text-decoration-none color-uai" href="http://gestionfic.uai.cl/login">{t("titulacion.pasantia.link_gestionfic")}</a>.</li>
                                        <li className="fs-6">{t("titulacion.pasantia.guia_practica5_texto1")}</li>
                                        <li className="fs-6">{t("titulacion.pasantia.guia_practica6_texto1")}<a className="text-decoration-none color-uai" href="mailto:concejodocente.fic@uai.cl">{t("titulacion.pasantia.correo_consejodocente")}</a>.</li>
                                        
                                    </ul>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                    <div className="p-3"/>
                                </div>
                            </div>

                            <div className="bg-grey">
                                <div className="position-relative">     
                                    <div className="p-3"/>
                                    <h1 className="text-center fs-3">{t("titulacion.pasantia.titulo_reglamento")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                    <div className="p-3"/>
                                    <p className="text-start fs-6">{t("titulacion.pasantia.reglamento_descripcion1")}<a className="text-decoration-none color-uai" href={reglamentopasantia}>{t("titulacion.pasantia.reglamento_descripcion_enlace")}</a>{t("titulacion.pasantia.reglamento_descripcion2")}</p>
                                    <div className="p-3"/>
                                    <ul>
                                        <li className="fs-6">{t("titulacion.pasantia.reglamento_objetivos")}</li> 
                                        <li className="fs-6">{t("titulacion.pasantia.reglamento_requisitos")}</li>
                                        <li className="fs-6">{t("titulacion.pasantia.reglamento_empresas")}</li>
                                        <li className="fs-6">{t("titulacion.pasantia.reglamento_modalidades")}</li>
                                        <li className="fs-6">{t("titulacion.pasantia.reglamento_deberes")}</li>
                                        <li className="fs-6">{t("titulacion.pasantia.reglamento_otros")}</li>
                                    </ul>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                    <div className="p-3"/>
                                </div>
                            </div>
                            <div className="p-3"/> 
                            <h1 className="text-center fs-3">{t("titulacion.pasantia.titulo_preguntas")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                            <div className="p-3"/>
                            <Button
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.requisitos.pregunta")}</p>
                        </Button>
                        <Collapse in={open}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.requisitos.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen2(!open2)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open2}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.modalidad.pregunta")}</p>
                        </Button>
                        <Collapse in={open2}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.modalidad.respuesta")}</p>
                                <ul>
                                    <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.modalidad.respuesta_fulltime")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.modalidad.respuesta_partime")}</li>
                            </ul>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                               <div className="p-2"/>
                        <Button
                            onClick={() => setOpen3(!open3)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open3}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.carga_horaria.pregunta")}</p>
                        </Button>
                        <Collapse in={open3}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <ul>
                                    <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.carga_horaria.respuesta_fulltime")}</li>
                                    <li className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.carga_horaria.respuesta_partime")}</li>
                            </ul>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                    
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen4(!open4)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open4}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.sin_convenio.pregunta")}</p>
                        </Button>
                        <Collapse in={open4}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.sin_convenio.respuesta_texto1")}<a className="text-decoration-none color-uai" href="https://wkf.ms/3LzkIUs">{t("titulacion.pasantia.sin_convenio_formulario")}</a>{t("titulacion.pasantia.sin_convenio.respuesta_texto2")}<a className="text-decoration-none color-uai" href=" https://view.monday.com/7047028100-5d4ff8b7e8f9ceb66633829bc4fdfd21?r=use1">{t("titulacion.pasantia.sin_convenio_estado_link")}</a>{t("titulacion.pasantia.sin_convenio.respuesta_texto3")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen5(!open5)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open5}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.plazo.pregunta")}</p>
                        </Button>
                        <Collapse in={open5}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.plazo.respuesta_texto1")}<a className="text-decoration-none color-uai" href="http://gestionfic.uai.cl/login">{t("titulacion.pasantia.link_gestionfic")}</a>{t("titulacion.pasantia.plazo.respuesta_texto2")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen6(!open6)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open6}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.oferta_pasantias.pregunta")}</p>
                        </Button>
                        <Collapse in={open6}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.oferta_pasantia.respuesta_texto")} <a className="text-decoration-none color-uai" href="https://view.monday.com/4646454937-90321b766cd9d01e65a2980828a5a585?r=use1">{t("titulacion.pasantia.oferta_pasantia.respuesta_enlace")}</a>.</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen7(!open7)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open7}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.homologacion.pregunta")}</p>
                        </Button>
                        <Collapse in={open7}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.homologacion.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen8(!open8)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open8}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.remuneracion.pregunta")}</p>
                        </Button>
                        <Collapse in={open8}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.remuneracion.respuesta")} </p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen9(!open9)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open9}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.convenio.pregunta")}</p>
                        </Button>
                        <Collapse in={open9}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.convenio.respuesta_texto1")} <a className="text-decoration-none color-uai" href="https://view.monday.com/3363094659-04594d43d0706af427105d07ca5769d8?r=use1">{t("titulacion.pasantia.convenio.respuesta_enlace")}</a>{t("titulacion.pasantia.convenio.respuesta_texto2")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen10(!open10)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open10}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.asignacion_pasantia.pregunta")}</p>
                        </Button>
                        <Collapse in={open10}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.asignacion_pasantia.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen11(!open11)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open11}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.inscripcion_profesor.pregunta")}</p>
                        </Button>
                        <Collapse in={open11}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.inscripcion_profesor.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen12(!open12)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open12}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.estado_convenio.pregunta")}</p>
                        </Button>
                        <Collapse in={open12}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.estado_convenio.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen13(!open13)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open13}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.certificado_pasantia.pregunta")}</p>
                        </Button>
                        <Collapse in={open13}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.certificado_pasantia.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen14(!open14)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open14}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.suspendido_pasantia.pregunta")}</p>
                        </Button>
                        <Collapse in={open14}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.suspendido_pasantia.respuesta_texto1")} <a className="text-decoration-none color-uai" href="https://wkf.ms/3Go4MBY">{t("titulacion.pasantia.suspendido_pasantia.respuesta_formulario")}</a>{t("titulacion.pasantia.suspendido_pasantia.respuesta_texto2")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-2"/>
                        <Button
                            onClick={() => setOpen15(!open15)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open15}
                            className="bg-uai btn btn-outline-light w-100 p-1"
                            >
                            <p className="fs-5">{t("titulacion.pasantia.finalizacion_pasantia.pregunta")}</p>
                        </Button>
                        <Collapse in={open15}>
                            <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                                <div className="p-2"/>
                                <p className="col-md-8 offset-md-2 fs-6">{t("titulacion.pasantia.finalizacion_pasantia.respuesta")}</p>
                                <div className="p-2"/>
                            </div>
                        </Collapse>
                        <div className="p-3"/>
                        <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>             
                            </div>
                    </div>

                    </div>
                </div>
            </div>
        </div>
    </>
    )
}



export default PasantiaTitulacion;