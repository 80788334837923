import React, {useState} from "react";
import {Button, Collapse} from "react-bootstrap";
import about from "../../resources/about.jpeg"
import { useTranslation } from "react-i18next";
import Procedimiento from "../Practicas/pdf/Procedimiento_en_Certificación_MS_Excel_FIC.pdf";
import instructivo from "../Practicas/pdf/Instructivo_para_pago_de_Certificación_Profesional_PPO101_2025.pdf"
import guia_presentacion from "../Practicas/pdf/Guia_presentacion_de_bitacora.pdf"
import material from "../Practicas/pdf/Material_de_preparacion _Certificacion_MS_Excel.pdf"

const PreguntasPracticas = () =>{
    const [t, i18n]= useTranslation("global");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);
    const [open9, setOpen9] = useState(false);
    const [open10, setOpen10] = useState(false);
    const [open11, setOpen11] = useState(false);
    const [open12, setOpen12] = useState(false);
    const [open13, setOpen13] = useState(false);
    const [open14, setOpen14] = useState(false);
    const [open15, setOpen15] = useState(false);
    const [open16, setOpen16] = useState(false);
    const [open17, setOpen17] = useState(false);
    return(
    <>
    <div className="d-none d-xl-block">
        <div className="">        
                    <div className="card position-relative ms-xl-4 me-xl-4">
                        <img src={about}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("practicas.preguntas.titulo")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
        <div className="d-flex">
            <div className="col-md-6 offset-md-3">
                <div className="position-relative">
                    <div className="p-3"/>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.requisitos_academicos")}</p>
                    </Button>
                    <Collapse in={open}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.requisitos_academicos")}</li>
   
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen2(!open2)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open2}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.procedimiento_practica_aceptada")}</p>
                    </Button>
                    <Collapse in={open2}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta1.procedimiento_practica_aceptada")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta2.procedimiento_practica_aceptada")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen3(!open3)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open3}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.datos_inscripcion")}</p>
                    </Button>
                    <Collapse in={open3}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_necesitas")}</p>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_fechas")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_pais")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_razon_social")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_rut")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_domicilio")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_datos_supervisor")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_departamento")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_trabajo")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_supervisor_cargo")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_supervisor_titulo")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen4(!open4)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open4}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.practica_objetada")}</p>
                    </Button>
                    <Collapse in={open4}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta1.practica_objetada")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.mail_practicaslaborales")}</a> {t("practicas.preguntas.respuesta2.practica_objetada")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta3.practica_objetada")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen5(!open5)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open5}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.practica_pendiente")}</p>
                    </Button>
                    <Collapse in={open5}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta1.practica_pendiente")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta2.practica_pendiente")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.mail_practicaslaborales")}</a> {t("practicas.preguntas.respuesta3.practica_pendiente")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen6(!open6)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open6}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.practica_rechazada")}</p>
                    </Button>
                    <Collapse in={open6}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta1.practica_rechazada")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.mail_practicaslaborales")}</a> {t("practicas.preguntas.respuesta2.practica_rechazada")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen7(!open7)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open7}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.documentos_certificados")}</p>
                    </Button>
                    <Collapse in={open7}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.documentos_certificados")}<a className="text-decoration-none color-uai" href="https://solicitudes.uai.cl/">{t("practicas.preguntas.pagina_solicitudes")}</a></li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen8(!open8)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open8}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.certificados_practica")}</p>
                    </Button>
                    <Collapse in={open8}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta1.certificados_practica")}</li>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta2.certificados_practica")}</li>
                            </ul>
                        
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen9(!open9)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open9}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.entrega_informe")}</p>
                    </Button>
                    <Collapse in={open9}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta1.entrega_informe")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=252">{t("practicas.preguntas.respuesta_link.entrega_informe")}</a>{t("practicas.preguntas.respuesta2.entrega_informe")}<strong>{t("practicas.preguntas.respuesta3_negrilla.entrega_informe")}</strong>{t("practicas.preguntas.respuesta4.entrega_informe")}<strong>{t("practicas.preguntas.respuesta5_negrilla.entrega_informe")}</strong></li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen10(!open10)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open10}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.tiempo_nota")}</p>
                    </Button>
                    <Collapse in={open10}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            
                            <table class = "table table-hover table-bordered w-75 col-md-8 offset-md-2">
                                <thead class= "table-dark">
                                    <tr>
                                    <th scope="col">{t("practicas.preguntas.aspecto_tabla-informe_titulo")}</th>
                                    <th scope="col">{t("practicas.preguntas.aspecto_tabla-nota_titulo")}</th>

                                    </tr>
                                </thead>
                                <tbody>
                
                                                <tr>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-informe_opcion1")}</td>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-nota_opcion1")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-informe_opcion2")}</td>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-nota_opcion2")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-informe_opcion3")}</td>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-nota_opcion3")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-informe_opcion4")}</td>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-nota_opcion4")}</td>
                                                </tr>
                                </tbody>
                            </table>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen11(!open11)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open11}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.informacion")}</p>
                    </Button>
                    <Collapse in={open11}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.informacion")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.mail_practicaslaborales")}</a>.</li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen12(!open12)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open12}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.certificado_excel")}</p>
                    </Button>
                    <Collapse in={open12}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.certificado_excel")}</li>
                            
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen13(!open13)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open13}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.practica_excel")}</p>
                    </Button>
                    <Collapse in={open13}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.practica_excel")} <a className="text-decoration-none color-uai" href={Procedimiento}>{t("practicas.preguntas.respuesta_link.practica_excel")}</a></li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen14(!open14)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open14}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.pago_excel")}</p>
                    </Button>
                    <Collapse in={open14}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.pago_excel")} <a className="text-decoration-none color-uai" href={instructivo}>{t("practicas.preguntas.respuesta_instructivo.pago_excel")}</a></li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen15(!open15)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open15}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.material_estudio")}</p>
                    </Button>
                    <Collapse in={open15}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href={material}>{t("practicas.preguntas.respuesta1.material_estudio")}</a></li>
                            <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href={guia_presentacion}>{t("practicas.preguntas.respuesta2.material_estudio")}</a></li>
                            
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen16(!open16)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open16}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.realizacion_examen")}</p>
                    </Button>
                    <Collapse in={open16}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.realizacion_examen")}</li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen17(!open17)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open17}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.certificado_adicional")}</p>
                    </Button>
                    <Collapse in={open17}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.certificado_adicional")}<a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.mail_practicaslaborales")}</a>.</li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    </div>
                </div>
            </div>
        </div>

        <div className="d-xl-none">
            <div className="p-3 mt-2"/>
            <div className="">        
                    <div className="card position-relative">
                        <img src={about}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("practicas.preguntas.titulo")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                        </div>
                    </div>
            </div>
            <div className="d-flex">
                <div className="col-md-6 offset-md-3">
                    <div className="position-relative">
                    <div className="p-3"/>
                    <Button
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.requisitos_academicos")}</p>
                    </Button>
                    <Collapse in={open}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.requisitos_academicos")}</li>

                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen2(!open2)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open2}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.procedimiento_practica_aceptada")}</p>
                    </Button>
                    <Collapse in={open2}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta1.procedimiento_practica_aceptada")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta2.procedimiento_practica_aceptada")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen3(!open3)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open3}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.datos_inscripcion")}</p>
                    </Button>
                    <Collapse in={open3}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <p className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_necesitas")}</p>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_fechas")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_pais")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_razon_social")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_rut")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_domicilio")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_datos_supervisor")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_departamento")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_trabajo")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_supervisor_cargo")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.datos_inscripcion_supervisor_titulo")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen4(!open4)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open4}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.practica_objetada")}</p>
                    </Button>
                    <Collapse in={open4}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta1.practica_objetada")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.mail_practicaslaborales")}</a> {t("practicas.preguntas.respuesta2.practica_objetada")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta3.practica_objetada")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen5(!open5)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open5}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.practica_pendiente")}</p>
                    </Button>
                    <Collapse in={open5}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta1.practica_pendiente")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta2.practica_pendiente")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.mail_practicaslaborales")}</a> {t("practicas.preguntas.respuesta3.practica_pendiente")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen6(!open6)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open6}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.practica_rechazada")}</p>
                    </Button>
                    <Collapse in={open6}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta1.practica_rechazada")} <a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.mail_practicaslaborales")}</a> {t("practicas.preguntas.respuesta2.practica_rechazada")}</li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen7(!open7)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open7}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.documentos_certificados")}</p>
                    </Button>
                    <Collapse in={open7}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.documentos_certificados")}<a className="text-decoration-none color-uai" href="https://solicitudes.uai.cl/">{t("practicas.preguntas.pagina_solicitudes")}</a></li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen8(!open8)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open8}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.certificados_practica")}</p>
                    </Button>
                    <Collapse in={open8}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta1.certificados_practica")}</li>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta2.certificados_practica")}</li>
                                
                            </ul>
                              
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen9(!open9)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open9}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.entrega_informe")}</p>
                    </Button>
                    <Collapse in={open9}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            <ul>
                                <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta1.entrega_informe")} <a className="text-decoration-none color-uai" href="https://webcursos.uai.cl/course/view.php?id=252">{t("practicas.preguntas.respuesta_link.entrega_informe")}</a>{t("practicas.preguntas.respuesta2.entrega_informe")}<strong>{t("practicas.preguntas.respuesta3_negrilla.entrega_informe")}</strong>{t("practicas.preguntas.respuesta4.entrega_informe")}<strong>{t("practicas.preguntas.respuesta5_negrilla.entrega_informe")}</strong></li>
                            </ul>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen10(!open10)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open10}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.tiempo_nota")}</p>
                    </Button>
                    <Collapse in={open10}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                            <div className="p-2"/>
                            
                            <table class = "table table-hover table-bordered w-75 col-md-8 offset-md-2">
                                <thead class= "table-dark">
                                    <tr>
                                    <th scope="col">{t("practicas.preguntas.aspecto_tabla-informe_titulo")}</th>
                                    <th scope="col">{t("practicas.preguntas.aspecto_tabla-nota_titulo")}</th>

                                    </tr>
                                </thead>
                                <tbody>
                
                                                <tr>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-informe_opcion1")}</td>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-nota_opcion1")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-informe_opcion2")}</td>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-nota_opcion2")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-informe_opcion3")}</td>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-nota_opcion3")}</td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-informe_opcion4")}</td>
                                                    <td scope="row">{t("practicas.preguntas.aspecto_tabla-nota_opcion4")}</td>
                                                </tr>
                                </tbody>
                            </table>
                            <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen11(!open11)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open11}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.informacion")}</p>
                    </Button>
                    <Collapse in={open11}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.informacion")} <a className="text-decoration-none color-uai" href="practicaslaborales.fic@uai.cl">{t("practicas.preguntas.mail_practicaslaborales")}</a>.</li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen12(!open12)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open12}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.certificado_excel")}</p>
                    </Button>
                    <Collapse in={open12}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.certificado_excel")}</li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen13(!open13)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open13}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.practica_excel")}</p>
                    </Button>
                    <Collapse in={open13}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.practica_excel")} <a className="text-decoration-none color-uai" href={Procedimiento}>{t("practicas.preguntas.respuesta_link.practica_excel")}</a></li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen14(!open14)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open14}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.pago_excel")}</p>
                    </Button>
                    <Collapse in={open14}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.pago_excel")} <a className="text-decoration-none color-uai" href={instructivo}>{t("practicas.preguntas.respuesta_instructivo.pago_excel")}</a></li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen15(!open15)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open15}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.material_estudio")}</p>
                    </Button>
                    <Collapse in={open15}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href={material}>{t("practicas.preguntas.respuesta1.material_estudio")}</a></li>
                            <li className="col-md-8 offset-md-2 fs-6"><a className="text-decoration-none color-uai" href={guia_presentacion}>{t("practicas.preguntas.respuesta2.material_estudio")}</a></li>
                            
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen16(!open16)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open16}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.realizacion_examen")}</p>
                    </Button>
                    <Collapse in={open16}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.realizacion_examen")}</li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    <Button
                        onClick={() => setOpen17(!open17)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open17}
                        className="bg-uai btn btn-outline-light w-100 p-1"
                    >
                    <p className="fs-5">{t("practicas.preguntas.pregunta.certificado_adicional")}</p>
                    </Button>
                    <Collapse in={open17}>
                        <div id="example-collapse-text" className="text-start bg-grey-dark mt-2">
                        <div className="p-2"/>
                        <ul>
                            <li className="col-md-8 offset-md-2 fs-6">{t("practicas.preguntas.respuesta.certificado_adicional")}<a className="text-decoration-none color-uai" href="mailto:practicaslaborales.fic@uai.cl">{t("practicas.preguntas.mail_practicaslaborales")}</a>.</li>
                        </ul>
                        <div className="p-2"/>
                        </div>
                    </Collapse>
                    <div className="p-2"/>
                    
                    </div>  
                </div>
            </div>  
        
        </div>
        
    </>
    )
}



export default PreguntasPracticas ;